@import 'src/styles/component-utils';

.iconContainer {
  color: $greyscale-400;

  &.iconSize-s {
    width: 16px;
    height: 16px;
  }

  &.iconSize-m {
    width: 24px;
    height: 24px;
  }

  &.iconSize-l {
    width: 32px;
    height: 32px;
  }

  &.disabled {
    color: $greyscale-200;
  }

  &.iconSize-s {
    //Fallback size in case there is no icon config for this size
    font-size: 14px;

    :global {

      //Solid icons that have different sizes then regular
      //Or icons that are only solid
      [data-prefix="fas"].fa- {
        &user {
          font-size: 11px;
        }

        &circle-check {
          font-size: 14px;
        }

        &star {
          font-size: 14px;
        }

        &paper-plane {
          font-size: 14px;
        }

        &list-check {
          font-size: 12px;
        }

        &user-plus {
          font-size: 11px;
        }

        &location-dot {
          font-size: 11px;
        }

        &square-check {
          font-size: 14px;
        }

        &circle-sort {
          font-size: 12px;
        }

        &circle-sort-up {
          font-size: 12px;
        }

        &circle-sort-down {
          font-size: 12px;
        }

        &headset {
          //Missing size
        }

        &circle {
          font-size: 8px;
        }

        &tag {
          font-size: 12px;
        }

        &copy {
          font-size: 12px;
        }

        &spinner {
          font-size: 14px;
        }

        &bars-sort {
          font-size: 14px;
        }

        &rotate-left {
          font-size: 12px;
        }

        &circle-minus {
          font-size: 14px;
        }

        &circle-plus {
          font-size: 14px;
        }

        &gear {
          font-size: 14px;
        }

        &square-minus {
          font-size: 14px;
        }
      }

      //Duotone icons that have different sizes then regular
      //Or icons that are only duotone
      [data-prefix="fad"].fa- {
        &spinner-third {
          font-size: 14px;
        }
      }

      .fa- {
        &bars {
          font-size: 14px;
        }

        &bell {
          font-size: 12px;
        }

        &xmark {
          font-size: 16px;
        }

        &palette {
          font-size: 14px;
        }

        &shapes {
          font-size: 14px;
        }

        &stopwatch {
          font-size: 16px;
        }

        &ellipsis-vertical {
          font-size: 16px;
        }

        &clock {
          font-size: 13px;
        }

        &building {
          font-size: 14px;
        }

        &bookmark {
          font-size: 14px;
        }

        &receipt {
          font-size: 12px;
        }

        &lightbulb {
          font-size: 14px;
        }

        &circle-user {
          font-size: 14px;
        }

        &user-group {
          font-size: 12px;
        }

        &envelope {
          font-size: 14px;
        }

        &phone-flip {
          font-size: 14px;
        }

        &briefcase {
          font-size: 14px;
        }

        &filter {
          font-size: 13px;
        }

        &clock-rotate-left {
          font-size: 12px;
        }

        &globe {
          font-size: 11.5px;
        }

        &users-medical {
          font-size: 12px;
        }

        &eye {
          font-size: 14px;
        }

        &eye-slash {
          font-size: 12px;
        }

        &sliders {
          font-size: 12px;
        }

        &circle-check {
          font-size: 16px;
        }

        &credit-card {
          font-size: 12px;
        }

        &table {
          font-size: 11px;
        }

        &chart-bar {
          font-size: 14px;
        }

        &message-dots {
          font-size: 12px;
        }

        &trash-can {
          font-size: 12px;
        }

        &message-pen {
          font-size: 12px;
        }

        &link {
          font-size: 12px;
        }

        &calendar-day {
          font-size: 14px;
        }

        &arrow-right-long {
          font-size: 14px;
        }

        &arrow-left-long {
          font-size: 14px;
        }

        &arrow-up-long {
          font-size: 14px;
        }

        &arrow-down-long {
          font-size: 14px;
        }

        &arrows-maximize {
          font-size: 14px;
        }

        &message-plus {
          font-size: 12px;
        }

        &down-to-line {
          font-size: 14px;
        }

        &square-dollar {
          font-size: 14px;
        }

        &file {
          font-size: 14px;
        }

        &square {
          font-size: 14px;
        }

        &list {
          font-size: 14px;
        }

        &arrows-left-right {
          font-size: 14px;
        }

        &circle-info {
          font-size: 14px;
        }

        &arrow-up-from-line {
          font-size: 14px;
        }

        &file-image {
          font-size: 14px;
        }

        &lock {
          font-size: 14px;
        }

        &magnifying-glass {
          font-size: 14px;
        }

        &user-tag {
          font-size: 12px;
        }

        &user {
          font-size: 14px;
        }

        &chart-pie-simple {
          font-size: 12px;
        }

        &filter-circle-dollar {
          //Missing size
        }

        &thumbs-up {
          //Missing size
        }

        &user-crown {
          font-size: 12px;
        }

        &file-check {
          font-size: 14px;
        }

        &users {
          //Missing size
        }

        &ellipsis {
          font-size: 14px;
        }

        &angles-left {
          font-size: 14px;
        }

        &angles-right {
          font-size: 14px;
        }

        &stars {
          //Missing size
        }

        &file-code {
          //Missing size
        }

        &building-columns {
          font-size: 14px;
        }

        &minus {
          font-size: 14px;
        }

        &check {
          font-size: 14px;
        }

        &circle {
          font-size: 14px;
        }

        &sitemap {
          font-size: 12px;
        }

        &arrow-up-right-from-square {
          font-size: 12px;
        }

        &check-double {
          font-size: 14px;
        }

        &shield-check {
          font-size: 14px;
        }

        &robot {
          font-size: 13px;
        }

        &key {
          font-size: 13px;
        }

        &gears {
          //This is flipped horisontally in mocks,
          //I don't think that we need to configuure it every time on the spot
          transform: scaleX(-1);
          font-size: 12px;
        }

        &calendar-circle-minus {
          //Missing size
        }

        &location-dot {
          font-size: 14px;
        }

        &message-lines {
          font-size: 13px;
        }

        &paperclip {
          font-size: 14px;
        }

        &calendar-circle-user {
          font-size: 12px;
        }

        &calendar-clock {
          font-size: 12px;
        }

        &money-check-dollar {
          font-size: 14px;
        }

        &globe-pointer {
          font-size: 12px;
        }

        &chart-tree-map {
          font-size: 14px;
        }

        &database {
          font-size: 14px;
        }

        &cloud-check {
          font-size: 12px;
        }

        &arrows-rotate {
          font-size: 12px;
        }

        &plus {
          font-size: 15px;
        }

        &chevron-down,
        &chevron-up {
          font-size: 15px;
        }

        &chevron-left,
        &chevron-right {
          font-size: 14px;
        }

        &circle-xmark {
          font-size: 12px;
        }

        &pencil {
          font-size: 12px;
        }

        &trello {
          font-size: 15px;
        }

        &paintbrush-fine {
          font-size: 12px;
        }

        &wand-magic-sparkles {
          font-size: 12px;
        }

        &cloud-arrow-up {
          font-size: 10px;
        }

        &slack {
          font-size: 11px;
        }

        &image {
          //Missing size
        }
      }
    }
  }

  &.iconSize-m {
    //Fallback size in case there is no icon config for this size
    font-size: 18px;

    :global {

      //Solid icons that have different sizes then regular
      //Or icons that are only solid
      [data-prefix="fas"].fa- {
        &user {
          font-size: 16px;
        }

        &circle-check {
          font-size: 17.5px;
        }

        &star {
          font-size: 20px;
        }

        &paper-plane {
          font-size: 18px;
        }

        &list-check {
          font-size: 18px;
        }

        &user-plus {
          font-size: 14px;
        }

        &location-dot {
          font-size: 16px;
        }

        &square-check {
          font-size: 18px;
        }

        &circle-sort {
          font-size: 18px;
        }

        &circle-sort-up {
          font-size: 18px;
        }

        &circle-sort-down {
          font-size: 18px;
        }

        &headset {
          font-size: 18px;
        }

        &circle {
          font-size: 12px;
        }

        &tag {
          font-size: 16px;
        }

        &copy {
          font-size: 15px;
        }

        &spinner {
          font-size: 18px;
        }

        &bars-sort {
          font-size: 21px;
        }

        &rotate-left {
          font-size: 16px;
        }

        &circle-minus {
          font-size: 18px;
        }

        &circle-plus {
          font-size: 18px;
        }

        &gear {
          font-size: 18px;
        }

        &square-minus {
          font-size: 18px;
        }
      }

      //Duotone icons that have different sizes then regular
      //Or icons that are only duotone
      [data-prefix="fad"].fa- {
        &spinner-third {
          font-size: 21px;
        }
      }

      .fa- {
        &bars {
          font-size: 18px;
        }

        &bell {
          font-size: 20px;
        }

        &xmark {
          font-size: 24px;
        }

        &palette {
          font-size: 20px;
        }

        &shapes {
          font-size: 20px;
        }

        &stopwatch {
          font-size: 24px;
        }

        &ellipsis-vertical {
          font-size: 23px;
        }

        &clock {
          font-size: 20px;
        }

        &building {
          font-size: 20px;
        }

        &bookmark {
          font-size: 21px;
        }

        &receipt {
          font-size: 16px;
        }

        &lightbulb {
          font-size: 20px;
        }

        &circle-user {
          font-size: 20px;
        }

        &user-group {
          font-size: 16px;
        }

        &envelope {
          font-size: 20px;
        }

        &phone-flip {
          font-size: 20px;
        }

        &briefcase {
          font-size: 18px;
        }

        &filter {
          font-size: 20px;
        }

        &clock-rotate-left {
          font-size: 18px;
        }

        &globe {
          font-size: 17.5px;
        }

        &users-medical {
          font-size: 18px;
        }

        &eye {
          font-size: 17px;
        }

        &eye-slash {
          font-size: 17px;
        }

        &sliders {
          font-size: 18px;
        }

        &circle-check {
          font-size: 21px;
        }

        &credit-card {
          font-size: 17px;
        }

        &table {
          font-size: 16px;
        }

        &chart-bar {
          font-size: 20px;
        }

        &message-dots {
          font-size: 18px;
        }

        &trash-can {
          font-size: 18px;
        }

        &message-pen {
          font-size: 18px;
        }

        &link {
          font-size: 18px;
        }

        &calendar-day {
          font-size: 18px;
        }

        &arrow-right-long {
          font-size: 18px;
        }

        &arrow-left-long {
          font-size: 18px;
        }

        &arrow-up-long {
          font-size: 18px;
        }

        &arrow-down-long {
          font-size: 18px;
        }

        &arrows-maximize {
          font-size: 18px;
        }

        &message-plus {
          font-size: 18px;
        }

        &down-to-line {
          font-size: 21px;
        }

        &square-dollar {
          font-size: 20px;
        }

        &file {
          font-size: 20px;
        }

        &square {
          font-size: 18px;
        }

        &list {
          font-size: 18px;
        }

        &arrows-left-right {
          font-size: 16px;
        }

        &circle-info {
          font-size: 20px;
        }

        &arrow-up-from-line {
          font-size: 20px;
        }

        &file-image {
          font-size: 20px;
        }

        &lock {
          font-size: 18px;
        }

        &magnifying-glass {
          font-size: 18px;
        }

        &user-tag {
          font-size: 16px;
        }

        &user {
          font-size: 18px;
        }

        &chart-pie-simple {
          font-size: 18px;
        }

        &filter-circle-dollar {
          font-size: 16px;
        }

        &thumbs-up {
          font-size: 18px;
        }

        &user-crown {
          font-size: 18px;
        }

        &file-check {
          font-size: 18px;
        }

        &users {
          font-size: 16px;
        }

        &ellipsis {
          font-size: 21px;
        }

        &angles-left {
          font-size: 21px;
        }

        &angles-right {
          font-size: 21px;
        }

        &stars {
          font-size: 18px;
        }

        &file-code {
          font-size: 18px;
        }

        &building-columns {
          font-size: 18px;
        }

        &minus {
          font-size: 18px;
        }

        &check {
          font-size: 18px;
        }

        &circle {
          font-size: 18px;
        }

        &sitemap {
          font-size: 17px;
        }

        &arrow-up-right-from-square {
          font-size: 16px;
        }

        &check-double {
          font-size: 21px;
        }

        &shield-check {
          font-size: 18px;
        }

        &robot {
          font-size: 16px;
        }

        &key {
          font-size: 16px;
        }

        &gears {
          //This is flipped horisontally in mocks,
          //I don't think that we need to configuure it every time on the spot
          transform: scaleX(-1);
          font-size: 18px;
        }

        &calendar-circle-minus {
          font-size: 18px;
        }

        &location-dot {
          font-size: 22px;
        }

        &message-lines {
          font-size: 18px;
        }

        &paperclip {
          font-size: 18px;
        }

        &calendar-circle-user {
          font-size: 18px;
        }

        &calendar-clock {
          font-size: 18px;
        }

        &money-check-dollar {
          font-size: 19px;
        }

        &globe-pointer {
          font-size: 18px;
        }

        &chart-tree-map {
          font-size: 18px;
        }

        &database {
          font-size: 18px;
        }

        &cloud-check {
          font-size: 18px;
        }

        &arrows-rotate {
          font-size: 18px;
        }

        &plus {
          font-size: 22 px;
        }

        &chevron-down,
        &chevron-up {
          font-size: 20px;
        }

        &chevron-left,
        &chevron-right {
          font-size: 18px;
        }

        &circle-xmark {
          font-size: 18px;
        }

        &pencil {
          font-size: 16px;
        }

        &trello {
          font-size: 22px;
        }

        &paintbrush-fine {
          font-size: 16px;
        }

        &wand-magic-sparkles {
          font-size: 18px;
        }

        &cloud-arrow-up {
          font-size: 18px;
        }

        &slack {
          font-size: 20px;
        }

        &image {
          font-size: 18px;
        }
      }
    }
  }

  &.iconSize-l {
    //Fallback size in case there is no icon config for this size
    font-size: 24px;

    :global {

      [data-prefix="fas"].fa- {
        &plus {
          font-size: 28px;
        }

        &wand-magic-sparkles {
          font-size: 24px;
        }

        &cloud-arrow-up {
          font-size: 24px;
        }

        &list-check {
          font-size: 24px;
        }

        &headset {
          font-size: 24px;
        }

        &image {
          font-size: 24px;
        }

        &spinner {
          font-size: 24px;
        }
      }

      [data-prefix="fad"].fa- {
        &spinner-third {
          font-size: 29px;
        }
      }

      .fa- {
        &palette {
          font-size: 24px;
        }

        &receipt {
          font-size: 24px;
        }

        &user-group {
          font-size: 22px;
        }

        &briefcase {
          font-size: 24px;
        }

        &credit-card {
          font-size: 24px;
        }

        &square-dollar {
          font-size: 28px;
        }

        &user-crown {
          font-size: 24px;
        }

        &file-check {
          font-size: 24px;
        }

        &users {
          font-size: 22px;
        }

        &stars {
          font-size: 26px;
        }

        &file-code {
          font-size: 26px;
        }

        &calendar-circle-minus {
          font-size: 22px;
        }

        &filter-circle-dollar {
          font-size: 22px;
        }
      }
    }
  }

}