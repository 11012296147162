@import 'src/styles/component-utils';

$option-height: 32px;

.container {
  box-sizing: content-box;
  font-size: 14px;
  background-color: $greyscale-0;
  border-radius: 6px;
  border: 1px solid $greyscale-300;
  box-shadow: 2px 0 8px #0002;
  max-height: $option-height * 4;
  overflow-y: auto;
  z-index: 35;
}

.categorizedMultiselect {
  max-height: $option-height * 10;
}

.chevron {
  cursor: pointer;
  transform: rotate(-180deg);
  width: 16px;
  @include transition();
}

.chevronActive {
  transform: rotate(0);
}

.notFound {
  @apply px-1 py-[5px] font-brand-b1 text-greyscale-500;
  border-radius: 6px;
}

